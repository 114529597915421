<template>
  <div>
    <h2 v-html="$t('search_processing_form_title')"></h2>
    <!-- Gesuchs-ID -->
    <v-text-field
      id="search_processing_form_requestid"
      v-model="searchCardStore.ProcessingForm_ident"
      :label="$t('search_processing_form_requestid')"
      type="text"
      @keyup.enter="search"
    />
    <!-- Status von -->
    <v-select
      id="search_processing_form_status_from"
      v-model="searchCardStore.ProcessingForm_statusIdMin"
      :label="$t('search_processing_form_status_from')"
      :items="formStatus"
      :item-title="this.$getLangKey()"
      item-value="id"
      clearable
      @keyup.enter="search"
    />
    <!-- Status bis -->
    <v-select
      id="search_processing_form_status_to"
      v-model="searchCardStore.ProcessingForm_statusIdMax"
      :label="$t('search_processing_form_status_to')"
      :items="formStatus"
      :item-title="this.$getLangKey()"
      item-value="id"
      clearable
      @keyup.enter="search"
    />
    <!-- Korrektur -->
    <v-select
      id="search_processing_form_is_correction"
      v-model="searchCardStore.ProcessingForm_isCorrection"
      :label="$t('search_processing_form_correction')"
      :items="processing_form_is_correction"
      item-title="text"
      item-value="value"
      clearable
      @keyup.enter="search"
    />
    <!-- Abschlussmonat von -->
    <DbmMonthPicker
      v-model="searchCardStore.ProcessingForm_validFrom"
      :dateLabel="$t('search_processing_valid_from')"
      :clearable="true"
      :rules="[$rules.isCHMonth]"
    />
    <!-- Abschlussmonat bis -->
    <DbmMonthPicker
      v-model="searchCardStore.ProcessingForm_validUntil"
      :dateLabel="$t('search_processing_valid_until')"
      :clearable="true"
      returnType="lastOfMonth"
      :rules="[$rules.isCHMonth]"
    />
    <!-- Periodizität -->
    <v-select
      id="search_processing_form_period_id"
      v-model="searchCardStore.ProcessingForm_periodId"
      :label="$t('search_processing_form_period_id')"
      :items="formPeriods"
      :item-title="this.$getLangKey()"
      item-value="id"
      clearable
      @keyup.enter="search"
    />

    <div class="link align-right" @click="expanded = !expanded">
      <span v-if="!expanded" v-html="$t('more')"></span>
      <span v-else v-html="$t('less')"></span>
    </div>
    <div v-if="expanded">
      <!-- expanded here -->
      <!-- Ablage-Nr -->
      <v-text-field
        id="search_processing_form_code"
        v-model="searchCardStore.ProcessingForm_code"
        :label="$t('search_processing_form_code')"
        type="text"
        @keyup.enter="search"
      />
    </div>
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import DbmMonthPicker from '@/components/dbmMonthPicker.vue'
import { useSearchProcessingFormStore } from '@/store/SearchProcessingFormStore'
import { useFormPeriodsStore } from '@/store/enums/FormPeriodsStore'
import { useFormStatusStore } from '@/store/enums/FormStatusStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchProcessingForm',
  extends: baseSearchVue,
  data() {
    return {
      expanded: false,
      menu1: false,
      menu2: false,
      processing_form_is_correction: [
        { value: 1, text: this.$t('search_processing_form_is_correction') },
        { value: 0, text: this.$t('search_processing_form_is_not_correction') }
      ]
    }
  },
  computed: {
    formStatus() {
      return useFormStatusStore().formStatus
    },
    formPeriods() {
      return useFormPeriodsStore().formPeriods
    }
  },

  components: {
    DbmMonthPicker: DbmMonthPicker
  },
  beforeMount() {
    this.setSearchCardStore(useSearchProcessingFormStore(), true)
    useFormStatusStore().fetchFormStatus()
    useFormPeriodsStore().fetchFormPeriods()
  }
})
</script>
